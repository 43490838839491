<template>
  <v-app>
    <navigation v-if="userId" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mutations } from "./plugins/store";
import Navigation from "./components/Navigation.vue";

export default {
  name: "App",
  data: () => ({
    drawer: null,
  }),
  computed: {
    userId() {
      return mutations.getUserId();
    },
  },
  methods: {
    logout() {
      mutations.setUserId("");
      this.$router.push({ name: "login" });
    },
  },
  components: { Navigation },
  metaInfo() {
    return {
      title: "VENUE: EXCON 2024 - REGISTRO DE VISITANTES",
      meta: [
        { name: 'description', content: 'Registra a los visitantes a tu exposición y descarga la base de datos en tiempo real' },
        { property: 'og:title', content: "VENUE: EXCON 2024 - REGISTRO DE VISITANTES" },
        { property: 'og:site_name', content: 'Venue' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  }
};
</script>
