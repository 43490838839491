import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../plugins/store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login' && !!store.userId) {
    return next({ name: 'home' })
  }
  if (to.name !== 'login' && !store.userId) {
    return next({ name: 'login' })
  }
  return next()
})

export default router
