<template>
  <v-container>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>EXCON 2024</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app right color="primary" temporary>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="white--text title"
              >{{userProfile.company}}</v-list-item-title
            >
            <v-list-item-subtitle> </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="white--text"
            >Cerrar sesión</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mutations } from "../plugins/store";
export default {
  name: "Navigation",

  data: () => ({
    drawer: null,
  }),
  computed: {
    userProfile() {
      return mutations.getUserProfile();
    },
  },
  methods: {
    logout() {
      mutations.setUserId("");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
</style>